<template>
  <div class="window-height window-width justify-center items-center">
    <div class="column">
      <div class="row">
        <div class="col-2 window-height" style="background: #00818a">
          <div
            class="row window-height justify-center transparente items-center"
          >
            <div class="content">
              <!-- <img src="../assets/wallet-logo.png" width="250" alt="">
                            <p style="font-size: 50px; font-family: sans-serif; color: white;">
                                Wallet Contago</p> -->
            </div>
          </div>
        </div>
        <div class="col-10 window-height" style="background: #f1f2f4">
          <div class="row window-height justify-center items-center">
            <q-card
              style="width: 500px; background-color: transparent"
              class="shadow-0"
            >
              <q-card-section class="q-pa-none">
                <q-form class="text-center">
                  <img
                    alt="Contago logo"
                    src="../assets/contago-wallet.png"
                    style="height: 100px"
                  />
                </q-form>
              </q-card-section>

              <q-card-section>
                <q-form class="q-gutter-md">
                  <!-- <a
                                        style="margin-bottom:-20px; font-size: 28px; font-family: sans-serif; color: #354052;">
                                        Inicio de
                                        sesión</a> -->
                  <q-input
                    bg-color="white"
                    square
                    outlined
                    v-model="nombreU"
                    type="usuario"
                    label="Usuario"
                  />
                  <q-input
                    bg-color="white"
                    square
                    outlined
                    v-model="password"
                    type="password"
                    label="Contraseña"
                  />
                </q-form>
              </q-card-section>
              <q-card-actions class="q-px-md">
                <q-btn
                  unelevated
                  color="primary"
                  size="lg"
                  class="full-width"
                  label="INICIAR SESIÓN"
                  @click="validarUsuario()"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      password: "",
      nombreU: "",
    };
  },
  computed: {},
  methods: {
    async validarUsuario() {
      //VALIDAMOS QUE EL USUARIO SEA VALIDO
      if (this.nombreU == "") {
        this.$q.notify({
          type: "info",
          icon: "mdi-information",
          message: "Ingrese un nombre de usuario.",
          timeout: 2000,
        });
        return;
      }
      if (this.nombreU == "") {
        this.$q.notify({
          type: "info",
          icon: "mdi-information",
          message: "Ingrese su contraseña.",
          timeout: 2000,
        });
        return;
      }
      this.$q.loading.show({ message: "<b>Validando usuario...</b>" });

      let respuesta = await axios
        .get("PaginaWeb/ValidaLogin/" + this.nombreU + "/" + this.password)
        .then((response) => {
          this.$q.loading.hide();
          return response.data;
        })
        .then((data) => {
          console.log(data);
          this.$store.state.usuario = { ...data };
          this.$q.loading.hide();
          this.$store.dispatch("guardarToken", data.token);
          this.nombreU = "";
          this.password = "";
        })
        .catch((err) => {
          console.log(err);
          this.$q.notify({ type: "negative", message: err.response.data });
          this.$q.loading.hide();
        });
    },
  },
};
</script>
<style>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
<style>
.transparente {
  background-image: url("../assets/wordcloud.png");
  opacity: 0.2;
  background-size: cover;
}
</style>
