<template>
  <q-layout>
    <q-page-container class="flex flex-center">
      <router-view />
      <!-- <span>{{ token }}</span> -->
    </q-page-container>
  </q-layout>
</template>
<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'LayoutDefault',

  components: {
    HelloWorld
  },

  data () {
    return {
      leftDrawerOpen: false
    }
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
}
</script>

<style>
</style>
