<template>
  <q-layout style="background: #f1f2f4" view="hHh lpR fFf">
    <q-pull-to-refresh @refresh="refresh">
      <q-dialog v-model="dialogOtroRetiro" persistent>
        <q-card style="min-width: 400px">
          <q-bar class="bg-primary text-white">
            <div>Retiro</div>
            <q-space />
          </q-bar>
          <q-card-section>
            <q-field label="Monto" v-model="cantidad" ref="refAbono">
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="comisionFormat"
                  v-show="floatingLabel"
                />
              </template>
              <template v-slot:prepend>
                <q-icon name="mdi-currency-usd" color="primary" />
              </template>
            </q-field>
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Aceptar" @click="PostRetiro()" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- DIALOG AGREGAR TERMINAL -->
      <q-dialog v-model="dialogTerminal" persistent>
        <q-card style="min-width: 400px">
          <q-bar class="bg-primary text-white">
            <div>Terminal</div>
            <q-space />
          </q-bar>
          <q-card-section>
            <q-input
              color="grey-4"
              label-color="primary"
              autofocus
              v-model="usuarioterminal"
              label="Correo"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-account-key-outline" color="primary" />
              </template>
            </q-input>
            <q-input
              color="grey-4"
              v-model="passterminal"
              label-color="primary"
              label="Password terminal"
              :type="isPwd ? 'password' : 'text'"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                  color="primary"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Aceptar" @click="PostTerminal()" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- DIALOG NUMERO DE GUIA -->
      <q-dialog v-model="dialogNumGuia" persistent>
        <q-card style="min-width: 400px">
          <q-bar class="bg-primary text-white">
            <div>Número de guia</div>
            <q-space />
          </q-bar>
          <q-card-section>
            <q-input
              color="grey-4"
              label-color="primary"
              autofocus
              v-model="numGuia"
              label="Guia"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-pound" color="primary" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Aceptar" @click="PostPendienteaEnviado()" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- DIALOG NUMERO DE CUENTA -->
      <q-dialog v-model="dialogNumCuenta" persistent>
        <q-card style="min-width: 400px">
          <q-bar class="bg-primary text-white">
            <div>Número de cuenta</div>
            <q-space />
          </q-bar>
          <q-card-section>
            <q-select
              label-color="primary"
              label="Banco"
              v-model="tipo"
              :options="['Albo', 'Tdn']"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-bank" color="primary" />
              </template>
            </q-select>
            <q-input
              color="grey-4"
              label-color="primary"
              autofocus
              v-model="numCuenta"
              label="Cuenta"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-pound" color="primary" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Aceptar" @click="PostEnviadoFinalizado()" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- DIALOG LISTA DE SOLICITUDES DE TARJETAS -->
      <q-dialog v-model="dialogSolicitudes" persistent>
        <q-card style="min-width: 600px">
          <q-bar class="bg-primary text-white">
            <div>Solicitudes de tarjetas</div>
            <q-space />
            <q-btn dense flat icon="close" v-close-popup>
              <q-tooltip>Close</q-tooltip>
            </q-btn>
          </q-bar>
          <q-card-section>
            <q-list style="max-width: 600px" v-for="a in solicitudesTarjetas">
              <q-item>
                <q-item-section avatar>
                  <q-avatar color="primary" text-color="white"
                    >{{ primeraLetra(a.nombre) }}
                    <template v-if="a.estado == 'pendiente'">
                      <q-badge color="orange" rounded floating />
                      <q-tooltip> Pendiente </q-tooltip>
                    </template>
                    <template v-if="a.estado == 'enviado'">
                      <q-badge color="blue" rounded floating />
                      <q-tooltip> Enviado </q-tooltip>
                    </template>
                    <template v-if="a.estado == 'finalizado'">
                      <q-badge color="green" rounded floating>
                        <q-icon name="done" color="white" />
                        <q-tooltip> Finalizado </q-tooltip>
                      </q-badge>
                    </template>
                  </q-avatar>
                </q-item-section>

                <q-item-section top>
                  <q-item-label
                    lines="1"
                    class="q-mt-xs text-body2 text-weight-bold text-primary text-uppercase"
                  >
                    <span class="cursor-pointer">{{ a.nombre }}</span>
                  </q-item-label>

                  <q-item-label caption lines="1">
                    <span class="text-grey-8">Estado: {{ a.est }}</span>
                    <span class="text-grey-8"> - {{ a.cp }}</span>
                  </q-item-label>
                  <q-item-label caption lines="1">
                    <span class="text-grey-8"
                      >Municipio: {{ a.municipio }}</span
                    >
                  </q-item-label>
                  <q-item-label caption lines="1">
                    <span class="text-grey-8">Colonina: {{ a.colonia }}</span>
                  </q-item-label>
                  <q-item-label caption lines="1">
                    <span class="text-grey-8"
                      >Dirección: {{ a.direccion }}</span
                    >
                  </q-item-label>
                </q-item-section>
                <q-item-section side top>
                  <q-item-label lines="1">
                    {{ a.fechasol }}
                  </q-item-label>
                  <q-item-label lines="1">
                    <span class="text-weight-medium">Tel: {{ a.celular }}</span>
                  </q-item-label>
                </q-item-section>
                <q-item-section side>
                  <div class="text-grey-8 q-gutter-xs">
                    <q-btn
                      color="primary"
                      size="16px"
                      flat
                      round
                      icon="mdi-check-bold"
                      @click="btnActualizaSolicitud(a)"
                    />
                    <!-- <q-btn color="primary" class="gt-xs" size="12px" flat dense round icon="delete" /> -->
                  </div>
                </q-item-section>
              </q-item>

              <q-separator spaced />
            </q-list>
          </q-card-section>
        </q-card>
      </q-dialog>

      <!-- DIALOG SALDOS -->
      <q-dialog v-model="dialogSaldosTotales" persistent>
        <q-card class="bg-primary text-white" style="min-width: 400px">
          <q-bar class="bg-primary text-white">
            <div>Saldos totales</div>
            <q-space />
            <q-btn dense flat icon="close" v-close-popup>
              <q-tooltip>Close</q-tooltip>
            </q-btn>
          </q-bar>
          <q-card-section>
            <q-field
              class="q-ma-md"
              readonly
              label="Deposito Efectivo Bruto"
              bg-color="white"
              v-model="totalDepositoEfectivoBruto"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>

            <q-field
              class="q-ma-md"
              readonly
              label="Deposito Efectivo Neto"
              bg-color="white"
              v-model="totalDepositoEfectivoNeto"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>
            <q-field
              class="q-ma-md"
              readonly
              label="Depositos Tarjeta Bruto"
              bg-color="white"
              v-model="totalDepositoTarjetaBruto"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>
            <q-field
              class="q-ma-md"
              readonly
              label="Depositos Tarjeta Neto"
              bg-color="white"
              v-model="totalDepositoTarjetaNeto"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>
            <q-field
              class="q-ma-md"
              readonly
              label="Comisión Efectivo"
              bg-color="white"
              v-model="totalComisionEfectivo"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>
            <q-field
              class="q-ma-md"
              readonly
              label="Comisión Tarjeta"
              bg-color="white"
              v-model="totalComisionTarjeta"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>

            <q-field
              class="q-ma-md"
              readonly
              label="Retiros Usuarios"
              bg-color="white"
              v-model="totalRetirosUsuario"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>

            <q-field
              class="q-ma-md"
              readonly
              label="Retiros Otros"
              bg-color="white"
              v-model="totalRetirosOtros"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>

            <q-field
              class="q-ma-md"
              readonly
              label="Saldo en cuenta"
              bg-color="white"
              v-model="saldoEnCuenta"
              dense
              filled
              ref="refAbono"
            >
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  readonly
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="moneyFormat"
                  v-show="floatingLabel"
                />
              </template>
            </q-field>
          </q-card-section>
        </q-card>
      </q-dialog>

      <!-- DIALGOG PARA CAMBIAR EL ESTADO DEL USUARIO -->
      <q-dialog v-model="dialogEstadoUsuario" persistent>
        <q-card style="min-width: 400px">
          <q-bar class="bg-primary text-white">
            <div>Cambiar estado del usuario</div>
            <q-space />
          </q-bar>
          <q-card-section>
            <q-select
              label-color="primary"
              label="Estado"
              v-model="estadoUsuario"
              :options="optionEstados"
              option-label="label"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-account" color="primary" />
              </template>
            </q-select>
            <q-input
              class="q-mt-sm"
              v-model="motivoEstado"
              label-color="primary"
              label="Motivo"
              autogrow
            />
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Aceptar" @click="PostEstatusUsuario()" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- DIALGOG PARA CAMBIAR EL PORCENTAJE DE COMISION -->
      <q-dialog v-model="dialogComision" persistent>
        <q-card style="min-width: 400px">
          <q-bar class="bg-primary text-white">
            <div>Asignar comisión</div>
            <q-space />
          </q-bar>
          <q-card-section>
            <q-field label="Comisión" v-model="comision" ref="refAbono">
              <template
                v-slot:control="{ id, floatingLabel, value, emitValue }"
              >
                <Money
                  :id="id"
                  class="q-field__input text-right"
                  :value="value"
                  @input="emitValue"
                  v-bind="comisionFormat"
                  v-show="floatingLabel"
                />
              </template>
              <template v-slot:append>
                <q-icon name="mdi-percent" color="primary" />
              </template>
            </q-field>
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Aceptar" @click="PostComision()" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- DIALOG PARA COMBROBAR RETIROS -->
      <q-dialog v-model="DialogoComprobarRetiros" persistent>
        <q-card style="min-width: 350px">
          <q-bar class="bg-primary text-white">
            <div>Nombre del archivo</div>
            <q-space />
          </q-bar>
          <q-card-section>
            <q-input
              color="grey-4"
              label-color="primary"
              autofocus
              v-model="nombreArchivo"
              label="Nombre"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-file-document-outline" color="primary" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions align="right" class="text-primary">
            <q-btn flat label="Cancelar" v-close-popup />
            <q-btn flat label="Aceptar" @click="PostComprobarRetiro()" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- DIALOG PARA MOSTRAR LOS MOVIMIENTOS POR USUARIO -->
      <q-dialog v-model="DialogMovimientos" persistent>
        <q-card style="width: 550px">
          <q-bar class="bg-primary text-white">
            <div>Movimientos</div>
            <q-space />
            <q-btn
              dense
              flat
              icon="mdi-export"
              @click="ExportExcelMovimientos()"
            >
              <q-tooltip>Exportar</q-tooltip>
            </q-btn>
            <q-btn dense flat icon="close" v-close-popup @click="GetUsuarios()">
              <q-tooltip>Close</q-tooltip>
            </q-btn>
          </q-bar>
          <q-item>
            <q-item-section avatar>
              <q-avatar color="primary" text-color="white">{{
                primeraLetra(this.usuario.nombre)
              }}</q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ this.usuario.nombre }}</q-item-label>
              <q-item-label caption>
                {{ this.usuario.correo }}
              </q-item-label>
              <q-item-label caption>
                Comisión:
                <a class="text-primary"
                  >{{ this.usuario.porcentajecomision + " %" }}
                </a>
              </q-item-label>
            </q-item-section>

            <q-item-section top side>
              <div class="text-grey-8 q-gutter-xs">
                <q-btn
                  icon="mdi-credit-card-plus-outline"
                  flat
                  round
                  color="primary"
                  @click="dialogNumCuenta = true"
                >
                  <q-tooltip>Cuenta de retiro</q-tooltip>
                </q-btn>
                <q-btn
                  v-if="this.usuario.terminal == 'NO'"
                  icon="mdi-credit-card"
                  flat
                  round
                  color="primary"
                  @click="abrirDialogTernimal()"
                >
                  <q-tooltip>Asignar terminal</q-tooltip>
                </q-btn>
                <q-btn
                  icon="mdi-percent-box"
                  flat
                  round
                  color="primary"
                  @click="abrirDialogComision()"
                >
                  <q-tooltip>Comisión</q-tooltip>
                </q-btn>
                <q-btn
                  icon="mdi-account-box"
                  flat
                  round
                  color="primary"
                  @click="abrirDialogEstadoUsuario()"
                >
                  <q-tooltip>Estatus del usuario</q-tooltip>
                </q-btn>
                <q-btn
                  icon="event"
                  flat
                  round
                  color="primary"
                  @click="DialogCalendarios = true"
                >
                  <q-tooltip>Consultar por fechas</q-tooltip>
                </q-btn>
              </div>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-scroll-area style="height: 500px">
            <q-list class="rounded-borders" v-for="a in movimientosUsuario">
              <q-item>
                <q-item-section>
                  <q-item-label
                    >Cantidad {{ FormatCurrency(a.cantidad) }}</q-item-label
                  >
                  <q-item-label caption
                    >Saldo anterior:
                    {{ FormatCurrency(a.saldoanterior) }}</q-item-label
                  >
                </q-item-section>
                <q-item-section side top>
                  <q-item-label caption>{{ a.fecha }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-separator />
            </q-list>
          </q-scroll-area>
        </q-card>
      </q-dialog>

      <!-- DIALOG PARA MOSTRAR LOS CALENDARIOS Y CONSULTAR -->
      <q-dialog v-model="DialogCalendarios" persistent>
        <q-card style="width: 350px">
          <q-bar class="bg-primary text-white">
            <div>Calendarios</div>
            <q-space />
            <q-btn dense flat icon="close" v-close-popup>
              <q-tooltip>Close</q-tooltip>
            </q-btn>
          </q-bar>
          <q-card-section>
            <q-input
              v-model="fehaIMasked"
              label="Fecha Inicial"
              class="q-mb-sm"
              name="event"
              filled
              dense
            >
              <template v-slot:before>
                <q-icon name="event" />
              </template>
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="fechaI">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Ok" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-input>

            <q-input v-model="fechaFMasked" label="Fecha Final" filled dense>
              <template v-slot:before>
                <q-icon name="event" />
              </template>
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="fechaF">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Ok" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-input>

            <q-btn
              outline
              color="primary"
              class="q-mt-sm full-width"
              label="Consultar"
              @click="PostMovimientosFechas()"
            />
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-header>
        <q-toolbar
          style="background-color: #f1f2f4"
          class="no-shadow q-pt-sm q-px-xl"
        >
          <!-- <q-btn flat round icon="mdi-wallet" color="primary" class="q-mr-sm" /> -->
          <q-toolbar-title>
            <img src="../assets/contago-wallet.png" width="180" alt="" />
            <!-- <div class="text-h6 text-primary">WALLET CONTAGO</div> -->
          </q-toolbar-title>
          <q-space />
          <div class="text-h6 text-primary q-mr-lg">
            {{ this.$store.state.usuario.username }}
          </div>
          <q-btn flat round>
            <q-avatar color="primary" text-color="white">{{ letra }}</q-avatar>
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup @click="salir()">
                  <q-item-section avatar>
                    <q-icon color="primary" name="mdi-logout" />
                  </q-item-section>

                  <q-item-section>Cerrar Sesión</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <!-- <q-btn color="primary" flat round icon="mdi-logout" class="q-mr-sm" @click="salir()" /> -->
        </q-toolbar>
      </q-header>

      <div
        v-if="loading"
        full-width
        full-height
        class="text-center q-px-xl q-mx-sm q-mt-xl q-pt-lg q-gutter-sm"
      >
        <q-spinner color="primary" size="8em" :thickness="2" />
      </div>

      <div v-else class="q-px-xl q-mx-sm q-mt-xl q-pt-lg q-gutter-sm">
        <div class="row">
          <div class="col-12">
            <q-card class="shadow-0 q-mb-xl">
              <q-card-section class="bg-primary text-white">
                <div
                  class="row q-gutter-sm"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div class="col-12">
                    <div
                      style="text-align: center; cursor: pointer"
                      @click="GetSaldosTotales()"
                    >
                      <div class="text-h6">Saldo en cuenta</div>
                      <div class="text-h3">
                        {{ FormatCurrency(totalCuenta) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row q-mt-lg q-gutter-sm justify-center">
                  <div class="col-2">
                    <q-field
                      readonly
                      label="Depositos"
                      bg-color="white"
                      v-model="totalDepositos"
                      dense
                      filled
                      ref="refAbono"
                    >
                      <template
                        v-slot:control="{ id, floatingLabel, value, emitValue }"
                      >
                        <Money
                          readonly
                          :id="id"
                          class="q-field__input text-right"
                          :value="value"
                          @input="emitValue"
                          v-bind="moneyFormat"
                          v-show="floatingLabel"
                        />
                      </template>
                    </q-field>
                  </div>
                  <div class="col-2">
                    <q-field
                      readonly
                      label="Disponible usuarios"
                      bg-color="white"
                      v-model="totalSaldoUsuarios"
                      dense
                      filled
                      ref="refAbono"
                    >
                      <template
                        v-slot:control="{ id, floatingLabel, value, emitValue }"
                      >
                        <Money
                          readonly
                          :id="id"
                          class="q-field__input text-right"
                          :value="value"
                          @input="emitValue"
                          v-bind="moneyFormat"
                          v-show="floatingLabel"
                        />
                      </template>
                    </q-field>
                  </div>
                  <div class="col-2">
                    <q-field
                      readonly
                      label="Retiros"
                      bg-color="white"
                      v-model="totalRetiros"
                      dense
                      filled
                      ref="refAbono"
                    >
                      <template
                        v-slot:control="{ id, floatingLabel, value, emitValue }"
                      >
                        <Money
                          readonly
                          :id="id"
                          class="q-field__input text-right"
                          :value="value"
                          @input="emitValue"
                          v-bind="moneyFormat"
                          v-show="floatingLabel"
                        />
                      </template>
                    </q-field>
                  </div>
                  <div class="col-2">
                    <q-field
                      readonly
                      label="Comisiones"
                      bg-color="white"
                      v-model="totalComisiones"
                      dense
                      filled
                      ref="refAbono"
                    >
                      <template
                        v-slot:control="{ id, floatingLabel, value, emitValue }"
                      >
                        <Money
                          readonly
                          :id="id"
                          class="q-field__input text-right"
                          :value="value"
                          @input="emitValue"
                          v-bind="moneyFormat"
                          v-show="floatingLabel"
                        />
                      </template>
                    </q-field>
                  </div>
                </div>
              </q-card-section>
              <q-tabs
                v-model="tab"
                :breakpoint="0"
                class="text-primary"
                dense
                align="justify"
              >
                <q-tab label="Movimientos" name="one" />
                <q-tab
                  :label="
                    'Retiros Pendientes ' + '(' + totalRetirosPendientes + ')'
                  "
                  name="two"
                />
              </q-tabs>

              <q-separator />

              <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="one">
                  <div class="row justify-between">
                    <!-- <div class="col-3 q-px-md">
                      <q-btn
                        class="full-width"
                        color="primary"
                        icon="mdi-credit-card-multiple"
                        label="Solicitudes de
                      tarjetas"
                        @click="GetSolicitudesTarjetas()"
                      />
                    </div> -->
                    <div class="col-2">
                      <q-btn
                        color="primary"
                        icon="mdi-cash-clock"
                        label="Crear retiro"
                        @click="dialogOtroRetiro = true"
                      />
                    </div>
                    <!-- <div class="col-4"></div> -->
                    <div class="col-3">
                      <q-input
                        filled
                        dense
                        debounce="300"
                        v-model="filter"
                        placeholder="Buscar"
                      >
                        <template v-slot:append>
                          <q-icon name="search" />
                        </template>
                        <template v-slot:after>
                          <q-btn
                            icon="mdi-refresh"
                            flat
                            round
                            color="primary"
                            @click="Actualizar()"
                          >
                            <q-tooltip>Actualizar</q-tooltip>
                          </q-btn>
                        </template>
                      </q-input>
                    </div>
                  </div>

                  <q-list
                    class="rounded-borders"
                    v-for="a in filteredMovimientos"
                  >
                    <q-item
                      clickable
                      v-ripple
                      class="q-my-lg"
                      @click="PostMovimientosUsuario(a)"
                    >
                      <q-item-section avatar>
                        <q-avatar
                          color="green"
                          text-color="white"
                          icon="mdi-cash-plus"
                        />
                      </q-item-section>
                      <q-item-section class="text-center">
                        <q-item-label lines="1">
                          <div class="text-h5">{{ a.nombre }}</div>
                        </q-item-label>
                        <q-item-label caption lines="2">
                          <div class="text-grey text-h6">{{ a.correo }}</div>
                        </q-item-label>
                        <div class="row q-gutter-sm justify-center">
                          <div class="col-2">
                            <q-field
                              readonly
                              label="Depositos"
                              v-model="a.depositos"
                              dense
                              filled
                              ref="refAbono"
                            >
                              <template
                                v-slot:control="{
                                  id,
                                  floatingLabel,
                                  value,
                                  emitValue,
                                }"
                              >
                                <Money
                                  readonly
                                  :id="id"
                                  class="q-field__input text-right"
                                  :value="value"
                                  @input="emitValue"
                                  v-bind="moneyFormat"
                                  v-show="floatingLabel"
                                />
                              </template>
                            </q-field>
                          </div>
                          <div class="col-2">
                            <q-field
                              readonly
                              label="Disponible"
                              v-model="a.depositosnetos"
                              dense
                              filled
                              ref="refAbono"
                            >
                              <template
                                v-slot:control="{
                                  id,
                                  floatingLabel,
                                  value,
                                  emitValue,
                                }"
                              >
                                <Money
                                  readonly
                                  :id="id"
                                  class="q-field__input text-right"
                                  :value="value"
                                  @input="emitValue"
                                  v-bind="moneyFormat"
                                  v-show="floatingLabel"
                                />
                              </template>
                            </q-field>
                          </div>
                          <div class="col-2">
                            <q-field
                              readonly
                              label="Retiros"
                              v-model="a.retiros"
                              dense
                              filled
                              ref="refAbono"
                            >
                              <template
                                v-slot:control="{
                                  id,
                                  floatingLabel,
                                  value,
                                  emitValue,
                                }"
                              >
                                <Money
                                  readonly
                                  :id="id"
                                  class="q-field__input text-right"
                                  :value="value"
                                  @input="emitValue"
                                  v-bind="moneyFormat"
                                  v-show="floatingLabel"
                                />
                              </template>
                            </q-field>
                          </div>
                          <div class="col-2">
                            <q-field
                              readonly
                              label="Comisión"
                              v-model="a.comision"
                              dense
                              filled
                              ref="refAbono"
                            >
                              <template
                                v-slot:control="{
                                  id,
                                  floatingLabel,
                                  value,
                                  emitValue,
                                }"
                              >
                                <Money
                                  readonly
                                  :id="id"
                                  class="q-field__input text-right"
                                  :value="value"
                                  @input="emitValue"
                                  v-bind="moneyFormat"
                                  v-show="floatingLabel"
                                />
                              </template>
                            </q-field>
                          </div>
                        </div>
                      </q-item-section>

                      <q-item-section side top>
                        <!-- <q-icon name="mdi-account" color="primary"> -->
                        <template v-if="a.estado == 'activo'">
                          <q-badge color="green" rounded floating />
                        </template>
                        <template v-else>
                          <q-badge color="red" rounded floating />
                        </template>
                        <!-- </q-icon> -->
                      </q-item-section>
                    </q-item>
                    <q-separator inset="item" />
                  </q-list>
                </q-tab-panel>

                <q-tab-panel name="two">
                  <div class="row">
                    <div class="col-12 text-right">
                      <q-btn
                        color="primary"
                        icon="mdi-download"
                        class="q-mr-sm"
                        label="Descargar TXT"
                        @click="GetArchivo()"
                      >
                      </q-btn>
                      <q-btn
                        color="primary"
                        icon="mdi-check-circle-outline"
                        label="Comprobar depositos"
                        @click="DialogoComprobarRetiros = true"
                      />
                    </div>
                  </div>
                  <q-list
                    class="rounded-borders"
                    v-for="a in retirosPendientes"
                  >
                    <q-item clickable v-ripple class="q-my-lg">
                      <q-item-section avatar>
                        <q-avatar
                          color="orange"
                          text-color="white"
                          icon="mdi-cash-clock"
                        />
                      </q-item-section>
                      <q-item-section class="text-left">
                        <q-item-label lines="1">
                          <div class="text-h5">
                            {{ FormatCurrency(a.cantidad) }}
                          </div>
                        </q-item-label>
                        <q-item-label caption lines="2">
                          <div class="text-grey text-h6">{{ a.fecha }}</div>
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side top>
                        <!-- <q-icon name="mdi-account" color="primary"> -->
                        <q-badge color="orange" rounded floating />
                        <!-- </q-icon> -->
                      </q-item-section>
                    </q-item>
                    <q-separator inset="item" />
                  </q-list>

                  <!-- <q-page-sticky position="bottom-right" :offset="[18, 18]">
                  <q-btn fab icon="mdi-download" color="primary">
                    <q-tooltip content-class="bg-purple" anchor="center left" self="top end"
                      content-style="font-size: 16px" :offset="[10, 10]">
                      Descargar archivo
                    </q-tooltip>
                  </q-btn>
                </q-page-sticky> -->
                </q-tab-panel>
              </q-tab-panels>
            </q-card>
          </div>
        </div>
      </div>
      <q-footer style="height: 30px" bordered class="bg-dark text-white">
        <q-toolbar> </q-toolbar>
      </q-footer>
    </q-pull-to-refresh>
  </q-layout>
</template>

<script>
import { Money } from "v-money";
import axios from "axios";
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import moment from "moment";
import * as XLSX from "xlsx";

export default {
  name: "Home",
  components: {
    HelloWorld,
    Money,
  },
  data() {
    return {
      fechaI: new Date(),
      fechaF: new Date(),
      moneyFormat: {
        decimal: ".",
        thousands: ",",
        precision: 2,
        masked: false,
        suffix: " MX",
      },
      comisionFormat: {
        decimal: ".",
        thousands: ",",
        precision: 2,
        masked: false,
      },
      totalDepositos: 0,
      totalSaldoUsuarios: 0,
      totalRetiros: 0,
      totalComisiones: 0,
      totalCuenta: 0,
      tab: "one",

      movimientos: [],
      retirosPendientes: [],
      DialogMovimientos: false,
      DialogCalendarios: false,
      usuario: { nombre: "" },
      movimientosUsuario: [],
      totalRetirosPendientes: 0,

      nombreArchivo: "",
      DialogoComprobarRetiros: false,
      filtroUsuario: [],
      filter: "",

      dialogEstadoUsuario: false,
      estadoUsuario: null,
      optionEstados: [{ label: "Activo", value: "activo" }],
      motivoEstado: null,

      dialogComision: false,
      comision: 0,

      dialogSaldosTotales: false,
      totalDepositoEfectivoBruto: 0,
      totalDepositoEfectivoNeto: 0,
      totalDepositoTarjetaBruto: 0,
      totalDepositoTarjetaNeto: 0,
      totalComisionEfectivo: 0,
      totalComisionTarjeta: 0,
      totalRetirosUsuario: 0,
      totalRetirosOtros: 0,
      saldoEnCuenta: 0,

      dialogSolicitudes: false,
      solicitudesTarjetas: [],
      numGuia: "",
      dialogNumGuia: false,
      solicitud: null,
      numCuenta: "",
      tipo: "",
      dialogNumCuenta: false,

      dialogTerminal: false,
      passterminal: "",
      usuarioterminal: "",
      isPwd: true,

      loading: false,

      dialogOtroRetiro: false,
      cantidad: 0,
    };
  },
  computed: {
    letra() {
      return this.$store.state.usuario.username.charAt(0).toUpperCase();
    },
    token() {
      return this.$store.state.usuario;
    },
    fehaIMasked() {
      moment.locale("es-mx");
      return this.fechaI ? moment(this.fechaI).format("DD/MMMM/yyyy") : "";
    },

    fechaFMasked() {
      moment.locale("es-mx");
      return this.fechaF ? moment(this.fechaF).format("DD/MMMM/yyyy") : "";
    },
    filteredMovimientos() {
      return this.movimientos.filter((a) => {
        // Filtrar los movimientos basados en el nombre o cualquier otro criterio que desees
        return a.nombre.toLowerCase().includes(this.filter.toLowerCase());
      });
    },
  },
  created() {
    this.Actualizar();
  },
  methods: {
    refresh(done) {
      setTimeout(() => {
        this.GetUsuarios();
        this.GetRetirosPendientes();
        this.GetTotalRetirosPendientes();
        done();
      }, 1000);
    },
    async Actualizar() {
      this.loading = true;
      await this.GetUsuarios();
      await this.GetRetirosPendientes();
      await this.GetTotalRetirosPendientes();
      this.loading = false;
    },
    async salir() {
      this.$q.loading.show({ message: "<b>Cerrando Sesión...</b>" });
      try {
        this.$q.loading.hide();
        this.$store.dispatch("salir");
      } catch (err) {
        console.log(err);
        this.$q.loading.hide();
      }
    },

    async GetSaldosTotales() {
      this.dialogSaldosTotales = true;
      try {
        let response = await axios.get("Movimientos/NGetSaldos");
        console.log(response);
        this.totalDepositoEfectivoBruto =
          response.data.totalDepositoEfectivoBruto;
        this.totalDepositoEfectivoNeto =
          response.data.totalDepositoEfectivoNeto;
        this.totalDepositoTarjetaBruto =
          response.data.totalDepositoTarjetaBruto;
        this.totalDepositoTarjetaNeto = response.data.totalDepositoTarjetaNeto;
        this.totalComisionEfectivo = response.data.totalComisionEfectivo;
        this.totalComisionTarjeta = response.data.totalComisionTarjeta;
        this.totalRetirosUsuario = response.data.totalRetirosUsuario;
        this.totalRetirosOtros = response.data.totalRetirosOtros;
        this.saldoEnCuenta = response.data.saldoEnCuenta;
      } catch (error) {
        console.log(error);
      }
    },

    async GetUsuarios() {
      try {
        let response = await axios.get("Usuarios/GetUsuarios");
        console.log(response);
        this.movimientos = response.data.lista;
        this.totalDepositos = response.data.totaldepositos;
        this.totalSaldoUsuarios = response.data.totaldepositosnet;
        this.totalRetiros = response.data.totalretiros;
        this.totalComisiones = response.data.totalcomosion;
        this.totalCuenta = response.data.totalcuenta;
      } catch (error) {
        console.log(error);
      }
    },

    btnActualizaSolicitud(item) {
      this.solicitud = item;
      switch (item.estado) {
        case "pendiente":
          this.dialogNumGuia = true;
          break;
        case "enviado":
          this.dialogNumCuenta = true;
          break;
      }
    },

    async GetSolicitudesTarjetas() {
      this.dialogSolicitudes = true;
      try {
        let response = await axios.get("Usuarios/VerSolTar");
        console.log(response);
        this.solicitudesTarjetas = response.data.lista;
      } catch (error) {
        console.log(error);
      }
    },

    async GetRetirosPendientes() {
      try {
        let response = await axios.get("Movimientos/Getretiros");
        // console.log(response)
        this.retirosPendientes = response.data.lista;
      } catch (error) {
        console.log(error);
      }
    },

    async GetTotalRetirosPendientes() {
      try {
        let response = await axios.get("Movimientos/Getcountretiros");
        // console.log(response)
        this.totalRetirosPendientes = response.data.nretiro;
      } catch (error) {
        console.log(error);
      }
    },

    async GetArchivo() {
      if (this.retirosPendientes.length == 0) {
        this.$q.notify({
          position: "top-right",
          type: "warning",
          message: `No hay retiros pendientes.`,
        });
        return;
      }
      this.$q.loading.show({ message: "<b>Creando archivo...</b>" });

      let idusuario = this.token.idUsuario.toString();
      try {
        let response = await axios.get(
          "Movimientos/GetArchivo?usuario=" + idusuario
        );
        this.$q.loading.hide();
        console.log(response);
        let base64 = response.data.archivo;
        await this.descargarArchivoBase64(base64, response.data.nombre);
        this.GetRetirosPendientes();
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },
    async PostMovimientosUsuario(item) {
      this.$q.loading.show({ message: "<b>Obteniendo listado...</b>" });

      console.log(item);
      this.usuario = item;
      let objeto = { id_usuario: item.id_usuario };
      try {
        let response = await axios.post("Movimientos/GetMovXu", objeto);
        console.log(response);
        this.movimientosUsuario = response.data.lista;
        this.DialogMovimientos = true;
        this.$q.loading.hide();
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    async PostComprobarRetiro() {
      this.$q.loading.show({ message: "<b>Comprobando retiros...</b>" });

      let objeto = {
        codigo: this.nombreArchivo,
        id_usuarioadm: parseInt(this.token.idUsuario),
      };
      console.log(objeto);
      try {
        let response = await axios.post("Movimientos/TerminaRetiro", objeto);
        console.log(response);
        this.$q.loading.hide();

        if (response.data.edopet == 0) {
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: `Retiros comprobados.`,
          });
          this.GetUsuarios();
          this.nombreArchivo = "";
          this.DialogoComprobarRetiros = false;
        } else {
          this.$q.notify({
            type: "negative",
            position: "top-right",
            message: `Verifique su información.`,
          });
        }
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    async PostMovimientosFechas() {
      this.$q.loading.show({ message: "<b>Consultando movimientos...</b>" });
      this.movimientosUsuario = [];
      let objeto = {
        id_usuario: this.usuario.id_usuario,
        Finicio: moment(this.fechaI).format("YYYY-MM-DD"),
        ffin: moment(this.fechaF).format("YYYY-MM-DD"),
      };
      try {
        let response = await axios.post("Movimientos/hitorialmovtab", objeto);
        console.log(response);
        this.movimientosUsuario = response.data.lista;
        this.DialogCalendarios = false;
        this.$q.loading.hide();
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    abrirDialogEstadoUsuario() {
      this.dialogEstadoUsuario = true;
    },

    async PostPendienteaEnviado() {
      this.$q.loading.show({ message: "<b>Actualizando solicitud...</b>" });

      let objeto = {
        id_usuario: parseInt(this.solicitud.id_usuario),
        guia: this.numGuia,
      };
      console.log(objeto);
      try {
        let response = await axios.post(
          "Usuarios/ActualizaestadoTarjeta",
          objeto
        );
        console.log(response);

        if (response.data.edopet == "0") {
          this.numGuia = "";
          this.dialogNumGuia = false;
          this.GetSolicitudesTarjetas();
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: `Solicitud actualizada.`,
          });
        } else {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: `Error al actulizar la solicitud, intentelo de nuevo.`,
          });
        }

        this.$q.loading.hide();
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    async PostEnviadoFinalizado() {
      this.$q.loading.show({ message: "<b>Asignando número de cuenta...</b>" });

      let objeto = {
        id_usuario: parseInt(this.usuario.id_usuario),
        ncuenta: this.numCuenta,
        tipo: this.tipo,
      };
      console.log(objeto);
      try {
        let response = await axios.post("Usuarios/TerminaSolTar", objeto);
        console.log(response);

        if (response.data.edopet == "0") {
          this.ncuenta = "";
          this.dialogNumCuenta = false;
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: `Cuenta asignada correctamente.`,
          });
        } else {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: `Error al actulizar la solicitud, intentelo de nuevo.`,
          });
        }

        this.$q.loading.hide();
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    async PostEstatusUsuario() {
      if (this.estadoUsuario == null) {
        this.$q.notify({
          position: "top-right",
          type: "warning",
          message: `Seleccione el estado que se asignara al usuario.`,
        });
        return;
      }

      if (this.motivoEstado == null || this.motivoEstado == "") {
        this.$q.notify({
          position: "top-right",
          type: "warning",
          message: `Indique el motivo del cambio de estado.`,
        });
        return;
      }

      this.$q.loading.show({ message: "<b>Cambiando estado...</b>" });

      let objeto = {
        id_usuarioadm: parseInt(this.token.idUsuario),
        id_usuario: this.usuario.id_usuario,
        estado: this.estadoUsuario.value,
        motivo: this.motivoEstado,
      };
      console.log(objeto);
      try {
        let response = await axios.post("Usuarios/Cambiaestadouser", objeto);
        console.log(response);

        this.$q.loading.hide();

        if (response.data.edopet == "0") {
          this.estadoUsuario = null;
          this.motivoEstado = null;
          this.dialogEstadoUsuario = false;
          this.DialogMovimientos = false;

          this.GetUsuarios();
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: `Usuario actualizad.`,
          });
        } else {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: `Error al actualizar, intentelo de nuevo.`,
          });
        }
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    async PostComision() {
      this.$q.loading.show({ message: "<b>Asignando comisión...</b>" });

      let objeto = {
        id_usuarioadm: parseInt(this.token.idUsuario),
        id_usuario: this.usuario.id_usuario,
        comision: this.comision,
      };
      console.log(objeto);
      try {
        let response = await axios.post(
          "Usuarios/CambiaComisionUsuario",
          objeto
        );
        console.log(response);
        this.$q.loading.hide();
        if (response.data.edopet == "0") {
          this.comision = 0;
          this.dialogComision = false;
          this.DialogMovimientos = false;
          this.GetUsuarios();
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: `Comisión actualizada.`,
          });
        } else {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: `Error al asignar comisión. intentelo de nuevo.`,
          });
        }
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    abrirDialogComision() {
      this.comision = this.usuario.porcentajecomision;
      this.dialogComision = true;
    },

    abrirDialogTernimal() {
      this.dialogTerminal = true;
    },

    async PostTerminal() {
      this.$q.loading.show({ message: "<b>Asignando terminal...</b>" });

      let objeto = {
        usuarioterminal: this.usuarioterminal,
        passterminal: this.passterminal,
        id_usuario: parseInt(this.usuario.id_usuario),
      };
      console.log(objeto);
      try {
        let response = await axios.post("Usuarios/AddTerminal", objeto);
        console.log(response);

        if (response.data.edopet == "0") {
          this.passterminal = "";
          this.dialogTerminal = false;
          this.GetUsuarios();
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: `Se asigano la información correctamente.`,
          });
        } else {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: `Error al actulizar, intentelo de nuevo.`,
          });
        }

        this.$q.loading.hide();
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    async PostRetiro() {
      this.$q.loading.show({ message: "<b>Creando retiro...</b>" });

      let objeto = {
        id_usuarioadm: parseInt(this.token.idUsuario),
        cantidad: this.cantidad,
      };

      console.log(objeto);
      try {
        let response = await axios.post(
          "Movimientos/InsertaRetiroOtros",
          objeto
        );
        console.log(response);

        if (response.data.edopet == "0") {
          this.cantidad = 0;
          this.dialogOtroRetiro = false;
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: `Retiro creado correctamente.`,
          });
        } else if (response.data.edopet == "-3") {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: `Error al crear el retiro, saldo insuficiente.`,
          });
        } else {
          this.$q.notify({
            position: "top-right",
            type: "negative",
            message: `Error al crear el retiro, intentelo de nuevo.`,
          });
        }

        this.$q.loading.hide();
      } catch (error) {
        this.$q.loading.hide();
        console.log(error);
      }
    },

    ExportExcelMovimientos() {
      const data = this.movimientosUsuario;
      const dataRows = data.map((item) => [
        item.foliopago,
        item.cantidad,
        item.saldoanterior,
        item.fecha,
      ]);

      // Crear un nuevo libro de Excel
      const workbook = XLSX.utils.book_new();

      // Crear una nueva hoja con los datos
      const worksheet = XLSX.utils.aoa_to_sheet([
        ["Folio de pago", "Cantidad", "Saldo anterior", "Fecha"],
        ...dataRows,
      ]);

      // Agregar la hoja al libro
      XLSX.utils.book_append_sheet(workbook, worksheet, "Movimientos");

      // Guardar el libro en un archivo .xlsx
      let nombreArchivo = "Movimientos - " + this.usuario.nombre + ".xlsx";
      XLSX.writeFile(workbook, nombreArchivo);
    },

    descargarArchivoBase64(base64String, nombreArchivo) {
      // Convertir la cadena Base64 a datos binarios
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Crear un objeto Blob que contiene los datos binarios
      const blob = new Blob([byteArray], { type: "text/plain" });

      // Crear un enlace temporal para descargar el archivo
      const enlaceDescarga = document.createElement("a");
      enlaceDescarga.href = window.URL.createObjectURL(blob);
      enlaceDescarga.download = nombreArchivo;

      // Hacer clic en el enlace para iniciar la descarga
      enlaceDescarga.click();
    },
    FormatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    primeraLetra(nombre) {
      // console.log(nombre)
      let nom = "";
      nom = nombre;
      return nom.charAt(0).toUpperCase();
    },

    filtrarObjetos() {
      const valorBusqueda = this.filter.toLowerCase();

      const objetosFiltrados = this.movimientos.filter((objeto) => {
        return objeto.nombre.toLowerCase().includes(valorBusqueda);
      });

      listaResultado.innerHTML = "";

      objetosFiltrados.forEach((objeto) => {
        const li = document.createElement("li");
        li.textContent = objeto.nombre;
        listaResultado.appendChild(li);
      });
    },

    filtroListaUsuarios() {
      this.filtroUsuario = this.movimientos.filter(
        (v) => v.nombre.toLocaleLowerCase().indexOf(needle) > -1
      );
    },
  },
};
</script>
