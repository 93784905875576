import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, name: 'Home', meta: { Administrador: true } },
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else {
    next({
      name: 'Login'
    })
  }
})
export default router
